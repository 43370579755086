import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Contact, TransitionMask } from '@components'
import { Grid, GridItem, } from '@components/grid'
import { PageProps } from '@types'



const IndexPage = ({data: { 
  sanityPage: {
    gridItems,
  }
}}: PageProps) => {

  return (
    <>
      <Grid>
        {gridItems.filter(p => !!p).map((item, i) => (
          <StyledGridItem key={item._key} {...item} index={i} />
        ))}
        <Contact />
      </Grid>
      
      <TransitionMask />
    </>
  )
}


const StyledGridItem = styled(props => <GridItem {...props} />)<{index: number}>`
  ${props => props.index === 0 && `min-height: calc(100vh - 200px);`}
`



export const query = graphql`
  query HomePageQuery {
    sanityPage(slug: {current: {eq: "home"}}) {
      ...pageFields
    }
  }
`

export default IndexPage
